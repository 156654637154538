import React from 'react';
import './anuncio.css'


export default function anuncio() {
    return (
        <div>
        <label for="one" class="alert-message">
          <strong> Reuniones:</strong> 19hs y repeticiones a las 10hs, 12hs y 15hs / Jueves 19.30hs / Escuela Dominical 10hs / Lecturas Diarias: 7-8-9 hs
        </label> 
      </div> 
    );
  
  }

