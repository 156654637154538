import React from "react";
import { Helmet }  from 'react-helmet'

export default function Player() {
  return(
    <div>
        <div class="cc_player" data-username="otgvgmig">Loading ...</div>
        Current song: <a href="http://pro02.caster.fm:2199/tunein/otgvgmig.pls" class="cc_streaminfo" data-type="song" data-username="otgvgmig">Loading ...</a><br />
        Bit rate: <span class="cc_streaminfo" data-type="bitrate" data-username="otgvgmig"></span><br />
        Current listeners: <span class="cc_streaminfo" data-type="listeners" data-username="otgvgmig"></span><br />
          <Helmet>
            <script language="javascript" type="text/javascript" src="https://pro02.caster.fm:2199/system/player.js"></script>
            <script language="javascript" type="text/javascript" src="https://pro02.caster.fm:2199/system/streaminfo.js"></script>
          </Helmet>
        </div>
  )

 
}