import React from 'react';
import './App.css'
import Player  from './player'
import Footer from './footer'
import header from './lib/header3.png'
import Anuncio from './anuncio'

function App() {
    return (
      <div className="App">
        <img src={header} className='logo' alt='Iglesia Cristina Evangelica'/>
          <h2 className='title'>"Permanezca el Amor Fraternal"</h2>
          <h3 className='title'>Hebreos 13:1</h3>
          <Anuncio/>  
          <div className='player'>
            <Player/>
          </div>
          <Footer/>
      </div>
    );
  
  }

export default App;
