import React from "react";
import './footer.css'
import { FaCloud, FaYoutube, FaMap,FaMailBulk, FaMusic } from 'react-icons/fa';


export default function Footer() {
  return(
        <footer className='footer'>
            <h4> <FaMap size={20}/> J.M Bosch 743 - Villa Bosch</h4>
            <a href='mailto:VillaBoschIglesia@gmail.com'><h4><FaMailBulk size={20}/> VillaBoschIglesia@gmail.com</h4></a>
            <a href='https://app.box.com/v/Boletines'><h4><FaCloud size={20}/> Boletin Mensual</h4></a>
            <a href='https://youtube.com/channel/UCzDnUId30Yogb7030aV60Cg'><h4 className="last"><FaYoutube size={20}/> Youtube</h4></a>
            <a href='https://drive.google.com/file/d/1NhXyGjXUT_koKQnJCcxl07iT_Sckh9dl/view?usp=drivesdk' target="_blank" rel="noreferrer"><h4 className="last"><FaMusic size={20}/> Coros</h4></a>

      </footer>
  )

}
